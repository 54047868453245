<template>
  <WithQuery :base-filters="baseFilters">
    <div slot-scope="{ filters }">
      <ReportsListView
        :view-title="`Viewing ${firstName}'s Reports`"
        :items-per-page="9"
        :filters="filters"
      />
    </div>
  </WithQuery>
</template>

<script>
import * as appConfig from '@src/app.config'
import ReportsListView from '@components/ReportsListView/ReportsListView'
import WithQuery from '@components/DataProviders/WithQuery'

import { HumanFields, ReportStatuses } from '@constants/knack'

import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'MyReportsListView',
  components: { WithQuery, ReportsListView },
  metaInfo: {
    title: 'My Reports',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {}
  },
  computed: {
    ...routeState(['query']),
    ...mapGetters([
      'firstName',
      'getUserEmail',
      'isAdmin',
      'isForeman',
      'isDispatcher',
    ]),
    baseFilters() {
      let rules = [
        {
          field: HumanFields.DAILY_REPORT.FOREMANS_EMAIL,
          operator: 'is',
          value: this.getUserEmail.toLocaleLowerCase(),
        },
        {
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is not',
          value: ReportStatuses.LOCKED,
        },
      ]

      // Only show "Scheduled" reports to Admin, Foreman, or Dispatcher
      if (!this.isAdmin && !this.isDispatcher) {
        rules.push({
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is not',
          value: ReportStatuses.SCHEDULED,
        })
      }
      return { rules }
    }, // baseFilters
  },
  methods: {},
}
</script>
